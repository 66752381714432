import React from "react"
import { Link, graphql } from "gatsby"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { sanityConfig } from "../utils/sanity"
import { InlineMediaCard } from "../components/media-card/InlineMediaCard"

import { GatsbyImage } from "gatsby-plugin-image"
import Banner from "../components/banner"
import Layout from "../components/layout"
import Seo from "../components/seo"

const truncateString = (str) => {
  if (str.length <= 155) {
    return str
  }

  return str.slice(0, 155) + "[...]"
}

export default function PositionAndPolicyPage({ data }) {
  const firstPositionStatement =
    data.firstPositionStatement.edges?.length > 0
      ? data.firstPositionStatement.edges[0]
      : undefined

  // Get feature image object for gatsby-image
  const featureImage = firstPositionStatement
    ? getGatsbyImageData(
        firstPositionStatement.node.featureImage.asset.id,
        { maxHeight: 400 },
        sanityConfig
      )
    : null

  return (
    <Layout>
      <Seo title="Position & Policy" />

      {firstPositionStatement ? (
        <section
          id="heroFeaturedEvent"
          className="z-0 overflow-hidden sm:aspect-w-16 sm:aspect-h-9 md:aspect-h-7 xl:aspect-h-5 2xl:aspect-h-4 py-4 "
        >
          {featureImage && (
            <GatsbyImage
              className="hidden sm:block"
              image={featureImage}
              style={{ position: "absolute", width: "100%", height: 800 }}
            />
          )}

          <div className="container mx-auto h-full sm:flex items-center">
            <div className="max-w-2xl bg-indigo-900 text-white lg:shadow-xl w-full p-6 md:p-8">
              <h1 className="serif font-extrabold text-xl lg:text-4xl">
                {firstPositionStatement.node.title}
              </h1>

              <span className="block my-5 font-bold">
                Published {firstPositionStatement.node.publishedAt}
              </span>

              <p className="prose lg:prose-lg text-white mb-5 mx-1">
                {truncateString(firstPositionStatement.node.excerpt)}
              </p>

              <Link
                to={`/position-and-policy/${firstPositionStatement.node.slug.current}`}
                className="inline-block transition bg-indigo-700 hover:bg-indigo-600 px-5 py-3 rounded font-semibold"
              >
                Read More
              </Link>
            </div>
          </div>
        </section>
      ) : (
        <Banner>Position &amp; Policy</Banner>
      )}

      {data.allSanityPositionStatement &&
        data.allSanityPositionStatement.edges.length > 0 && (
          <section id="pastEvents">
            <div className="container mx-auto px-3 py-8">
              <ul className="mt-6 grid grid-cols-1 gap-5 sm:gap-6 xl:gap-x-16 xl:gap-y-8 lg:grid-cols-2">
                {data.allSanityPositionStatement.edges.map((doc) => {
                  const { node } = doc

                  return (
                    <li>
                      <InlineMediaCard
                        to={`/position-and-policy/${node.slug.current}`}
                        fallbackText={node.publishedAt}
                        title={node.title}
                      />
                    </li>
                  )
                })}
              </ul>
            </div>
          </section>
        )}
    </Layout>
  )
}

export const query = graphql`
  query {
    page: sanityPage(slug: { current: { eq: "position-and-policy" } }) {
      title
      _rawBody
      featureImage {
        asset {
          url
        }
      }
    }

    firstPositionStatement: allSanityPositionStatement(
      limit: 1
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          excerpt
          publishedAt(formatString: "MMM Do YYYY")
          slug {
            current
          }
          featureImage {
            asset {
              id
            }
          }
        }
      }
    }

    allSanityPositionStatement(
      skip: 1
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "YYYY")
          slug {
            current
          }
        }
      }
    }

    guidelines: allSanityGuideline {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }
  }
`
